<div style="max-height: 100%; overflow: hidden;">
    <mat-toolbar>
        <h1 mat-dialog-title>configure table</h1>
        <span class="spacer"></span>
        <button class="close" mat-button mat-dialog-close><span class="material-icons">
                close
            </span> Close</button>
    </mat-toolbar>

<div>
    <h1 mat-dialog-title style="margin: 10px"> Nothing to customize here yet! Be ready for some cool features soon!</h1>
    <!-- <mat-dialog-actions>
        <button mat-button class="apply-button-clear">Clear</button>
        <button mat-button mat-dialog-close class="apply-button-apply" (click)="openSnackBar()">Apply
            Selections</button>
    </mat-dialog-actions> -->
</div>

    <!-- <div>
        <p class="content-title"></p>
        <div class="checkboxes">
            <div class="col1">
                <mat-checkbox [ngModel]="defaultCheck" color="primary">Show All</mat-checkbox><br>
                <mat-checkbox [ngModel]="defaultCheck" color="primary">AFE</mat-checkbox><br>
                <mat-checkbox [ngModel]="defaultCheck" color="primary">Primary Job Type</mat-checkbox><br>
                <mat-checkbox [ngModel]="defaultCheck" color="primary">AFE($)</mat-checkbox>
            </div>
            <div class="col1">
                <mat-checkbox [ngModel]="defaultCheck" color="primary">WV Field Est.($)</mat-checkbox><br>
                <mat-checkbox [ngModel]="defaultCheck" color="primary">SAP($)</mat-checkbox><br>
                <mat-checkbox [ngModel]="defaultCheck" color="primary">WV Field Est./AFE($)</mat-checkbox><br>
                <mat-checkbox [ngModel]="defaultCheck" color="primary">WV Field Est./AFE(%)</mat-checkbox>
            </div>
            <div class="col1">
                <mat-checkbox [ngModel]="defaultCheck" color="primary">WV Field Est./SAP(%)</mat-checkbox>
            </div>
        </div>
    </div>


</div> -->