import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'settings-table-modal',
  templateUrl: './settings-table-modal.component.html',
  styleUrls: ['./settings-table-modal.component.scss']
})
export class SettingsTableModalComponent implements OnInit {
  defaultCheck = true;
  toastMessage = "Columns configured successfully!"
  toastDismiss = "Dismiss"

  constructor(public _snackBar: MatSnackBar) { }

  openSnackBar() {
    this._snackBar.open(this.toastMessage, this.toastDismiss, {
      duration: 2000
    });
  }

  ngOnInit() {
  }

}
