import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
} from '@angular/router';
import { CalAngularService } from '@cvx/cal-angular';
import { environment } from 'src/environments/environment';
import { BusinessUnitService } from './services/business-unit.service';

@Injectable({ providedIn: 'root' })
export class AADGroupGuard implements CanActivate {
    private groups = environment.AADGroupNames.map(x => x.GroupName);

    constructor(
        private authService: CalAngularService,
        private buService: BusinessUnitService,
        private router: Router
    ) { }

    /**
     * Determines if user is in correct AAD Group, if not then redirects user to not found screen
     */
    canActivate(): boolean | Promise<boolean> {
        return this.authService.isInGroup(this.groups).then( async (inGroup: boolean) => {
            if (inGroup) {
                console.log('You are in one of the following groups: ' + this.groups + '.');
                if(!this.buService.initialized)
                {
                    await this.buService.initializeSelectedBU();
                }
                return true;
            }
            else {
                console.log('You are not in one of the following groups: ' + this.groups + '.')
                this.router.navigateByUrl('/unauthorized');
                return false;
            }
        })
            .catch(error => {
                console.log(this.groups + ' could not be found or is an incorrect group name. Error: ' + error);
                return false;
            })
    }

}
