import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICvxClaimsPrincipal } from '@cvx/cal-angular';

@Component({
    selector: 'landing',
    templateUrl: './landing.component.html'
})

/**
 * Landing page for base URL, Guard will force sign in then redirect to cost compare page
 */
export class LandingComponent implements OnInit {


    constructor(private router: Router) { }
    currentUserProfile: ICvxClaimsPrincipal;

    ngOnInit() {
        this.router.navigate(["/cost-compare"]);
    }

}