<div class="container" style="height: 100%; width: 100%;" >
    <img src="assets/images/404.svg" style="background-size: cover; background-attachment: fixed;" >
    <div class="text-block">
        <h1 class="error">500</h1>
        <hr style="width: 35%; color: white;">
        <p class="text">You are not part of the correct groups to access this tool.  Please fill out an access request 
            <a target="_blank"
              href="https://chevron.service-now.com/itserviceportal?id=sc_cat_item_guide&sys_id=8b909f7cdb0c2058182e1dca489619fb">here
            </a> under the Cost Assurance Tool.</p>
    </div>
</div>