export interface ICostCategory {
    wellID: string;
    wellName: string;
    afeNumber: string;
    afeCost: number;
    primaryJobType: string;
    code: string;
    codeDescription: string;
    calculateWVDiffCost?: number;
    calculateWVDiffPercent?: number;
    wellSummary?: string;
    costCategory: string;
    GLDetails: GLDetails[];

}

export class CostCategory implements ICostCategory {
    MixCategoryFlag: boolean;
    MixCategoryParent: string;
    FieldEstSubTotal: number;
    SapSubTotal: number;
    calculateWVDiffCost: number;
    calculateWVDiffPercent: number;
    CalculateSAPDiffCostTotal: number;
    CalculateSAPDiffPercentTotal: number;
    HasPendingInvoices: boolean;
    HasApprovedInvoices: boolean;
    constructor(public wellName: string,
        public primaryJobType: string,
        public afeNumber: string,
        public wellSummary: string,
        public wellID: string,
        public code: string,
        public costCategory: string,
        public codeDescription: string,
        public afeCost: number,
        public GLDetails: GLDetails[]) {
    }
}

export class GLDetails {
    sapCost?: number;
    fieldEst?: number;
    calculateSAPDiffCost?: number;
    calculateSAPDiffPercent?: number;
    codeDescription: string;
    sapVendors: sapVendor[];
    wvVendors: wvVendor[];
    TotalSapVendor: number;
    TotalWVVendor: number;
    VendorLabel: string;
    invoiceVendors: sapVendor[];
}

export class sapVendor {
    vendor: string;
    supplierId: string;
    calculatedSap: string;
    TotalAribaInvoice: number;
    TotalSapInvoice: number;
    TotalGrandInvoice: number;
    description: string;
    invoices: invoice[];
    filteredInvoices: invoice[];
    jobType: string;
}

export class wvVendor {
    vendor_description: string;
    cost: number;
}

export class invoice {
    invoiceReceived: string;
    aribaDocId: string;
    contractId: string;
    discountAmount: string;
    description: string;
    partKey: string;
    sapDescription: string;
    Quantity: string;
    unitMeasure: string;
    unitPrice: string;
    statusString: string;
    approvedDate: string;
    EndDate: string;
    category: string;
    aribaLink: string;
    DSO: string;
}

export class invoiceFilter {
    codeDescription: string[];
    vendor: string[];
    invoiceReceived: Date[];
    costCategory: string[];
    hasPendingInvoices: boolean;
    hasApprovedInvoices: boolean;
}

export class categoryType {
    GL: CostCategory[];
}

export class category{
    categoryType: categoryType[];
}

export class costCategoryRoot{
    wellID: string;
    wellName: string;
    afeNumber: string;
    primaryJobType: string;
    costCategories: category[];
}

export enum FilterColumns {
    operator = 'operator',
    area = 'area',
    fieldName = 'fieldName',
    sapWellName = 'sapWellName',
    rigName = 'rigName',
    primaryJobType = 'primaryJobType',
    formation = 'formation',
    jobEndYear = 'jobEndYear',
    favorite = 'favorite',
    devArea = 'DevAreaCode',
    padName = 'PadName'
  }


export enum InvoiceFilterColumns {
    codeDescription = 'codeDescription',
    vendor = 'vendor',
    invoiceReceived = 'invoiceReceived',
    costCategory = 'costCategory',
    invoiceStatus = 'invoiceStatus'
  }

  export class SelectedWell{
    wellID: string;
    afeNumber: string;
    jobStart: string;
}

export class SelectedVendor{
    SupplierID: string;
    description: string;
    afeNumber: string;
    wellID: string;
    jobType: string;
}

export class DataRefresh {
    Datasource: string;
    LastRefresh: Date;
}

export class UserFavorite {
    CAI: string;
    WellId: string;
    AfeNumber: string;
    JobStart: string;
}

export class WellDetailsColumn {
    Title: string;
    IWellAttributeName: string;

    constructor(title: string, wellAttributeName: string)
  {
    this.Title = title;
    this.IWellAttributeName = wellAttributeName;
  }
}
