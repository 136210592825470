<!doctype html>
<html lang="en">

<head>

  <meta charset="utf-8">
  <title>Cost Assurance</title>
  <base href="/">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="icon" type="image/x-icon" href="favicon.ico">
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
  <style>
    .splash-container {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: #0066b2;
      z-index: 999;
    }

    .logo {
      display: block;
      width: 150px;
      height: 150px;
      margin-bottom: 20px;
    }

    .title {
      color: #ffffff;
      font-size: 20px;
      font-weight: 500;
      font-family: Roboto, "Helvetica Neue", sans-serif;
      margin-bottom: 5px;
    }

    .subtitle {
      margin-bottom: 20px;
      color:  #d5e4ef;
      font-family: Roboto, "Helvetica Neue", sans-serif;
      font-size: 16px;
      font-weight: 300;
      opacity: 0.8;
    }
  </style>
</head>

<body>
    <div class="splash-container">
      <img class="logo" alt="chevron logo" src="assets/images/ChevronLogo.svg" />
      <h1 class="title">cost assurance</h1>
      <h2 class="subtitle">Signing In...</h2>
    </div>
</body>

</html>