<div style="max-height: 100%; overflow: hidden;">
    <mat-toolbar>
        <h1 mat-dialog-title>Data Refresh</h1>
        <span class="spacer"></span>
        <button class="close" mat-button mat-dialog-close><span class="material-icons">
                close
            </span> Close</button>
    </mat-toolbar>


<div class="table-container">
    <table
    mat-table
    [dataSource]="data.dataRefresh"
    multiTemplateDataRows
    matSort
    class="mat-elevation-z8"
    >
        <ng-container matColumnDef="dataSource">
            <th
                class="th-1"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                style="border-right: 2px solid #dbdcdd"
            >
                Data Source
            </th>
            <td
                class="td-1"
                style="border-right: 2px solid #dbdcdd"
                mat-cell
                *matCellDef="let dataRefresh"
            >
                {{ dataRefresh.Datasource }}
            </td>
        </ng-container>
        <ng-container matColumnDef="LastRefresh">
            <th
                class="th-1"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                style="border-right: 2px solid #dbdcdd"
            >
                Last Time Data Refreshed
            </th>
            <td
                class="td-1"
                style="border-right: 2px solid #dbdcdd"
                mat-cell
                *matCellDef="let dataRefresh"
            >
                {{ dataRefresh.LastRefresh }}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
