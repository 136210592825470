<mat-toolbar class="auth-header">
  <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button class="auth-header__logo">
        <img
          class="auth-header__logo__icon"
          src="assets/images/logo-chevron.png"
        />
      </button>
      <div class="auth-header__title">Cost Assurance</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
      <ul
        class="auth-header-ul"
        fxLayout
        fxLayoutGap="16px"
        fxLayoutAlign="center"
      >
        <li>
          <div class="Personal_Info_Container">
            <div id="Greeting_Row">
              <span class="greetings">Welcome</span>&nbsp;
              <span class="username">{{ name }}</span>
              <button mat-button [matMenuTriggerFor]="menu">
              <span class="material-icons"> account_circle </span>
              </button>
            </div>
            <div id="BU_Row">
              <mat-form-field (click)="$event.stopPropagation()" appearance="fill">
              <mat-label>Select a BU</mat-label>
              <mat-select (selectionChange)="onBUChange($event)" [(value)]="businessUnitName">
                <mat-option value="MCBU">MCBU</mat-option>
                <mat-option value="GOMBU">GOMBU</mat-option>
                <mat-option value="RBU">RBU</mat-option>
                <mat-option value="CEMREC">CEMREC</mat-option>
                <mat-option value="LABU">LABU</mat-option>
              </mat-select>
            </mat-form-field>
            </div>
          </div>
          
          
          
          <mat-menu #menu="matMenu">
            <a
              target="_blank"
              href="https://chevron.sharepoint.com/sites/DCDigital"
              ><button mat-menu-item>
                <span class="material-icons"> description </span>Wells Platform Page
              </button></a
            >
            <a
              target="_blank"
              href="https://go.chevron.com/CA_UserGuide"
              ><button mat-menu-item>
                <span class="material-icons"> description </span>Cost Assurance User Guide
              </button></a
            >
            <a
              target="_blank"
              href="https://chevron.service-now.com/itserviceportal?id=sc_cat_item_guide&sys_id=8b909f7cdb0c2058182e1dca489619fb"
              ><button mat-menu-item>
                <span class="material-icons"> bug_report </span>Report a Bug
              </button></a
            >
            
            <mat-divider></mat-divider>
          </mat-menu>
        </li>
      </ul>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<div fxLayout="column" class="parent">
  <div fxFlex="30%" class="left">Left</div>
  <div fxFlex="70%" class="right">Right</div>
</div>
