import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { BU } from 'src/app/interfaces/business-unit';
import { BusinessUnitService } from 'src/app/services/business-unit.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  name: string;
  businessUnitName: string;

  constructor(private authService: CalAngularService, 
    private router: Router,
    private buService: BusinessUnitService) { }
  currentUserProfile: ICvxClaimsPrincipal;

  ngOnInit() {
    this.getUserInfo();
    this.businessUnitName = BU[this.buService.getSelectedBU()];
  }

  getUserInfo() {
    this.name = this.authService.cvxClaimsPrincipal.name;
  }

  onBUChange(event: any) {
    this.buService.setSelectedBU(event.value);
    this.router.navigate(['/']);
  }

}