import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HeaderComponent } from './components/header/header.component';
import { MaterialModule } from './material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { SettingsTableModalComponent } from './components/settings-table-modal/settings-table-modal.component';
import { HttpClientModule } from '@angular/common/http';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// Environment
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CostCompareComponent } from './components/cost-compare/cost-compare.component';
import { CompareWellsComponent } from './components/compare-wells/compare-wells.component';
import { CacheInterceptor } from './services/cache.interceptor';
import { PbiReportsComponent } from './shared/pbi-reports/pbi-reports.component';
import { CostCategoriesComponent } from './components/cost-categories/cost-categories.component';
import { UniqueValuesPipe } from './pipes/unique-values.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { CalAngularModule } from '@cvx/cal-angular';
import { ReplaceNullWithTextPipe } from './pipes/replace-null-with-text.pipe';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DataInfoModalComponent } from './components/data-info-modal/data-info-modal.component';
import { DatePipe } from '@angular/common';
import { RemoveNullsByPipe } from './pipes/remove-nulls.pipe';
import { LandingComponent } from './components/landing/landing.component';
import { StopAtPipe } from './pipes/stop-at.pipe';
import { UnauthorizedComponent } from './components/unauthorized/notfound/unauthorized.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NotfoundComponent,
    UnauthorizedComponent,
    LandingComponent,
    SettingsTableModalComponent,
    DataInfoModalComponent,
    CostCompareComponent,
    PbiReportsComponent,
    CostCategoriesComponent,
    CompareWellsComponent,
    UniqueValuesPipe,
    OrderByPipe,
    StopAtPipe,
    ReplaceNullWithTextPipe,
    RemoveNullsByPipe
  ],
  entryComponents: [ SettingsTableModalComponent, DataInfoModalComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxMatSelectSearchModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxDaterangepickerMd.forRoot(),
    CalAngularModule.forRoot(environment.calConfig)
  ],
  providers: [MatDatepickerModule, MatNativeDateModule, DatePipe,
    {provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
