import { Component, OnInit } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { CvxClaimsPrincipal } from '@cvx/cal-angular';
import { MyMonitoringService } from './services/logging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

// Copied from CAL docs for autosign in: https://dev.azure.com/chevron/ITC-APES-Components/_git/CAL-Angular
export class AppComponent implements OnInit {
  loggedIn = false
constructor(
    private authService: CalAngularService,
    private myMonitoringService: MyMonitoringService
    ) { }

  ngOnInit()  {
    this.checkAccount();
  }

  checkAccount() {
    this.authService.isUserSignedIn().then((value: boolean) => {
      this.loggedIn = value;
      console.log("loggedIn: " + this.loggedIn);
      if(!value)
        this.login();
      this.myMonitoringService.logAuthUser(this.authService.cvxClaimsPrincipal.email);
      console.log(this.authService.cvxClaimsPrincipal);
      this.myMonitoringService.logPageView("LoginPage");
    })
  }

  login() {
    this.authService.userInitiatedSignIn().then((user: CvxClaimsPrincipal) => {
      if (this.authService.isUserSignedIn()) {
        this.loggedIn = true;
        console.log(this.authService.cvxClaimsPrincipal);
      }
      else {
        this.loggedIn = false;
      }
    })
  }

  logout() {
    this.authService.userInitiatedSignOut().then((value: boolean) => {
      this.loggedIn = value;
      this.myMonitoringService.clearAuthUser();
    })
  }
}
