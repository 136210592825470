import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataRefresh } from 'src/app/interfaces/cost-category';

@Component({
  selector: 'data-info-modal',
  templateUrl: './data-info-modal.component.html',
  styleUrls: ['./data-info-modal.component.scss']
})

export class DataInfoModalComponent implements OnInit {
  displayedColumns: string[] = ['dataSource', 'LastRefresh'];

  constructor(public _snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: {dataRefresh: DataRefresh[]})
  {
  }

  openSnackBar() {
  }

  ngOnInit() {
  }

}
