import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';
import { IWells } from '../interfaces/wells.interface';

@Pipe({
  name: 'orderBy',
  pure: true
})
export class OrderByPipe implements PipeTransform {

  transform(value: any[], propertyName: string): any[] {
    if (propertyName)
      return value.sort((a: any, b: any) => (a[propertyName]? a[propertyName].localeCompare(b[propertyName]): 1));
    else
      return value;
  }
}
