import { Pipe, PipeTransform } from '@angular/core';
import 'lodash';
import { IWells } from '../interfaces/wells.interface';

declare var _: any;
@Pipe({
  name: 'uniqueValues',
  pure: false
})
export class UniqueValuesPipe implements PipeTransform {

  transform(items: any[], args: string): any {
    // filter items array, items which match and return true will be kept, false will be filtered out

    return _.uniqBy(items, args);
}

}
